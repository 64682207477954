
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of Lawyers</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-md-6 col-lg-4 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <!-- <li @click.prevent="exportUsers()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li> -->
            </ul>
          </div>
          <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-slot:cell(image)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <img class="avatar rounded-circle" :src="absoluteUrl(data.item.image)" />
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.first_name }} {{ data.item.last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(mobile)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.mobile }}</span>
                </div>
              </template>
              <template v-slot:cell(email)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.email }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span v-html="statusBadge(data.item.status)" class=""></span>
                </div>
              </template>
              <template v-slot:cell(is_verified)="data">
                <span class="text-capitalize badge" :class="data.item.is_verified ? 'bg-primary' : 'bg-danger'"> 
                    {{ data.item.is_verified ? '✓' :'X' }} </span>
              </template>
              <template v-slot:cell(action)="data">
                <div class="dropdown dropstart no-arrow">
                  <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <!-- <li>
                      <button @click.prevent="initEdit(data.item)" class="dropdown-item"
                        type="button"> Edit </button>
                    </li> -->
                  </ul>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "lawyer-list",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      searchQuery: "",
      pageOptions: [10, 50, 100, 500, 1000],
      isSelectAll: false,
      columns: [
        {
            key: "image",
            label: "Image",
        },
        {
            key: "name",
            label: "Name",
        },
        {
            key: "mobile",
            label: "Phone",
        },
        {
            key: "email",
            label: "Email",
        },
        {
            key: "status",
            label: "Status",
        },
        {
          key: "is_verified",
          label: "Verified",
        },
        "action"
      ],
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    pageData(){
      return this.$store.state.lawyer.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/lawyers/${id}`});
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("lawyer/fetchLawyers", payload)
      .then(response => this.isLoading = false)
    },
    exportUsers() {
      const payload = {
        url: `/lawyers/export-excel?role=${this.defaultRole}`,
        filename: 'lawyers_'
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$store.dispatch('serverExport', payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate clients');
          }
      })
    },
    initializeAll(){
    }
  },
  created() {
    this.fetchItems()
  }
}

</script>

